<template>
  <div>
    <a-form-item label="卡号总数" :style="{ display: 'inline-block', width: '50%' }">
      <span class="cards-count">{{ cardsCount }}</span>
    </a-form-item>

    <a-form-item label="重复数量" :style="{ display: 'inline-block', width: '50%' }">
      <span class="cards-count">{{ duplicateCardsCount }}</span>
    </a-form-item>
  </div>
</template>

<script>
import { findExpectCancelCardsCountByCarrierAccount } from '@/api/cancel_card_manage'
export default {
  name: 'AllExpectCancelCardCount',
  props: {
    id: {
      type: Number,
      required: true
    },
    cardsCount: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      duplicateCardsCount: 0
    }
  },
  computed: {
    currentCardsCount: {
      get() {
        return this.cardsCount
      },
      set(val) {
        this.$emit('update:cardsCount', val)
      }
    }
  },
  created() {
    this.fetchExpectCancelCardsCountByCarrierAccount()
  },
  methods: {
    fetchExpectCancelCardsCountByCarrierAccount() {
      findExpectCancelCardsCountByCarrierAccount(this.id).then((res) => {
        if (res.code === 0) {
          this.currentCardsCount = res.data.expect_cancel_cards_count
        }
      })
    }
  }
}
</script>
